import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Select, Space, Divider, Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { SketchPicker } from 'react-color';

import { getManyWhiteLabels } from "../../Api/WhiteLabels.js";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const { Option } = Select;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormNetwork = (props) => {

    let { network } = props;

    const [form] = Form.useForm();

    let [data, setData] = useState([]);
    let [background, setBackground] = useState('#fff');
    let [fileList, setFileList] = useState([]);
    let [base64Url, setBase64Url] = useState(null);
    let [previewVisible, setPreviewVisible] = useState(false);
    let [previewImage, setPreviewImage] = useState(false);


    const option = data.map((d) => <Option key={d.id}>{d.name}</Option>);

    /**
     * 
     * @param {*} file 
     * @returns 
     */
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
    *
    * @param {*} value
    */
    const handleSearch = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setData((data = json.results));
                });
        }
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = async (values) => {
        let newNetwork = network !== null ? { ...network } : {};


        newNetwork.name = values.name;

        newNetwork.whiteLabel_id = parseInt(values.whiteLabel_id.value);
        if (values.color !== null) {
            newNetwork.color = values.color.hex;
        }

        newNetwork.phone = values.phone;

        if (fileList.length > 0) {
            newNetwork.logo = await getBase64(fileList[0].originFileObj);
        }
        props.formSubmit(newNetwork);
    };

    /**
     * 
     * @param {*} color 
     */
    const handleChangeComplete = (color) => {
        setBackground(color.hex);
    };

    /**
     * 
     * @returns 
     */
    const handleCancel = () => setPreviewVisible(false);

    /**
     * 
     * @param {*} file 
     */
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setBase64Url(file.preview)
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true)
    };

    /**
     * 
     * @param {*} param0 
     */
    const handleChange = ({ fileList }) => {
        setBase64Url(null);
        setFileList(fileList);
    }


    /**
    *
    */
    useEffect(() => {
        if (network !== null) {
            let formValues = {
                name: network.name,
                whiteLabel_id: {
                    key: null,
                    value: null,
                    label: null,
                },
                phone: null,
                color: null,
                logo: null
            };

            if (network.phone !== null) {
                formValues.phone = network.phone;
            }

            if (network.color !== null) {
                formValues.color = network.color;
                setBackground(network.color);
            }

            if (network.logo !== null) {
                setBase64Url(network.logo);
                setPreviewImage(network.logo);
            }

            if (network.white_label !== null) {
                formValues.whiteLabel_id = {
                    key: network.white_label.id,
                    value: network.white_label.id,
                    label: network.white_label.name,
                }
            }

            form.setFieldsValue(formValues);


        }
    }, [network]);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Form form={form} name="layout" onFinish={onFinish} layout="vertical">
            <Form.Item
                {...layout}
                label="Nom"
                name="name"
                hasFeedback
                rules={[
                    { required: true, message: "Merci d'indiquer un nom de réseau" },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Marque blanche"
                name="whiteLabel_id"
            >
                <Select
                    labelInValue={true}
                    showSearch
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    allowClear
                >
                    {option}
                </Select>
            </Form.Item>
            <Form.Item
                {...layout}
                label="Numéro de téléphone"
                name="phone"
                initialValue={null}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Couleur de personnalisation"
                name="color"
                initialValue={null}
            >
                <SketchPicker
                    color={background}
                    onChangeComplete={handleChangeComplete}
                />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Logo du réseau"
                name="logo"
            >
                {base64Url ? <img src={base64Url} alt="avatar" style={{ width: '105px', marginBottom: '20px' }} /> : null}
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    maxCount={1}
                >
                    {fileList.length >= 1 ? null : uploadButton}

                </Upload>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Form.Item>
            <Divider></Divider>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: "#2FAC66" }}>
                        <Link to="/networks">Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}

export default FormNetwork;