import React from 'react';
import { Redirect } from 'react-router-dom';

import { Layout, Divider, PageHeader, Select, Space, notification } from 'antd';

import { getManyNetworks, saveWoopConfiguration, getWoopConfiguration } from '../../Api/Woop.js';

import WoopForm from './WoopForm.js';

class WoopAdd extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            redirection: false,
            id: null,
            valueNetwork: undefined,
            dataNetwork: [],
            woop: null,
            network_id: null,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSearchNetwork = this.handleSearchNetwork.bind(this);
        this.onChangeNetwork = this.onChangeNetwork.bind(this);
    }

    /**
    *
    * @param {*} value
    */
    handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataNetwork: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeNetwork(value) {
        this.setState({ network_id: value });
        let data = {
            network_id: value
        }
        getWoopConfiguration(data)
            .then((res) => res.json())
            .then((json) => {
                if (json.length === 0) {
                    this.setState({ woop: null });
                } else {
                    this.setState({ woop: json });
                }
            });
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        let woop = this.state.woop;

        saveWoopConfiguration(values)
            .then(async (res) => {
                if (res.status !== 200) {
                    throw await res.json();
                }
                return res.json();
            })
            .then((data) => {
                if (woop !== null) {
                    notification['success']({
                        message: 'La configuration a bien été mise à jour'
                    });
                } else {
                    notification['success']({
                        message: 'La configuration a bien été enregistrée'
                    });
                }

                this.setState({ woop: data });
            })
            .catch((e) => {
                if (e && e.error) {
                    if (e.error === 'woop_invalid_client') {
                        notification['error']({
                            message: 'Impossible de se connecter à l\'api de Woop avec ces identifiants',
                        });
                    }
                }
            });
    };

    render() {
        const { redirection } = this.state;
        const { Content } = Layout;
        const { Option } = Select;

        if (redirection) {
            return (<Redirect to="" />)
        }

        return (
            <div>
                <PageHeader title="Woop"  />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Space>
                            <div className="filter-title">Réseau </div>
                            <Select
                                className="filter"
                                showSearch
                                value={this.state.network_id}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={this.handleSearchNetwork}
                                onChange={this.onChangeNetwork}
                                notFoundContent={null}
                            >
                                {this.state.dataNetwork.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Divider></Divider>
                        <WoopForm formSubmit={this.onSubmit} networkId={this.state.network_id} woop={this.state.woop} />
                    </div>
                </Content>
            </div>
        );
    }
}

export default WoopAdd;