import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Divider, Space, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { SketchPicker } from 'react-color';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormWhitelabel = (props) => {

    let { whitelabel } = props;

    const [form] = Form.useForm();
    let [background, setBackground] = useState('#fff');
    let [fileList, setFileList] = useState([]);
    let [base64Url, setBase64Url] = useState(null);
    let [previewVisible, setPreviewVisible] = useState(false);
    let [previewImage, setPreviewImage] = useState(false);

    const handleChangeComplete = (color) => {
        setBackground(color.hex);
    };

    /**
    * 
    * @param {*} file 
    * @returns 
    */
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * 
     * @returns 
     */
    const handleCancel = () => setPreviewVisible(false);

    /**
     * 
     * @param {*} file 
     */
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setBase64Url(file.preview)
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true)
    };

    /**
     * 
     * @param {*} param0 
     */
    const handleChange = ({ fileList }) => {
        setBase64Url(null);
        setFileList(fileList);
    }



    /**
     * 
     */
    useEffect(() => {
        if (whitelabel !== null) {
            let formValues = {
                name: whitelabel.name,
                phone: null,
                color: null,
                logo: null
            }

            if (whitelabel.phone !== null) {
                formValues.phone = whitelabel.phone;
            }

            if (whitelabel.color !== null) {
                formValues.color = whitelabel.color;
                setBackground(whitelabel.color);
            }

            if (whitelabel.logo !== null) {
                setBase64Url(whitelabel.logo);
                setPreviewImage(whitelabel.logo);
            }

            form.setFieldsValue(formValues);
        }
    })

    /**
     * 
     * @param {*} values 
     */
    const onFinish = async (values) => {
        let newWhiteLabel = whitelabel !== null ? { ...whitelabel } : {};

        newWhiteLabel.name = values.name;

        if (values.color !== null) {
            newWhiteLabel.color = values.color.hex;
        }

        newWhiteLabel.phone = values.phone;

        if (fileList.length > 0) {
            newWhiteLabel.logo = await getBase64(fileList[0].originFileObj);
        }

        props.formSubmit(newWhiteLabel);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Form.Item label="Nom" name="name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer une marque blanche' }]}>
                <Input name="name" />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Numéro de téléphone"
                name="phone">
                <Input />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Couleur de personnalisation"
                name="color">
                <SketchPicker
                    color={background}
                    onChangeComplete={handleChangeComplete}
                />
            </Form.Item>
            <Form.Item
                {...layout}
                label="Logo du réseau"
                name="logo"
            >
                {base64Url ? <img src={base64Url} alt="avatar" style={{ width: '105px', marginBottom: '20px' }} /> : null}
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    maxCount={1}
                >
                    {fileList.length >= 1 ? null : uploadButton}

                </Upload>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Form.Item>
            <Divider></Divider>
            <Form.Item {...tailLayout}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/whitelabels'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormWhitelabel;