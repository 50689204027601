import React from 'react';

import { Layout } from 'antd';

import logo from '../../logo.png';
import FormLogin from './FormLogin.js';

class Login extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        const { Content } = Layout;

        return (
            <Content className="login" style={{ padding: '50px 50px'}}>
                <div className="logo-login"><img src={logo} className="logoImgLogin" alt="logo" /></div>
                <div className="form-login" >
                    <h2 className="h2-login">Connexion</h2>
                    <FormLogin />
                </div>
            </Content>
        );
    }
}
export default Login