import config from '../Config.js';

/**
 * 
 * @returns 
 */
export function getManyUsers(data) {
    return fetch(config.hostApi + 'users', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @returns 
 */
export function getOneUser(id) {
    return fetch(config.hostApi + 'user/' + id, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @returns 
 */
export function updateUser(id, data) {
    return fetch(config.hostApi + 'user/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

export function deleteUser(id) {
    return fetch(config.hostApi + 'user/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}