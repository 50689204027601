import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";

import {
  Layout,
  Menu,
  Avatar,
  Button,
  ConfigProvider,
} from "antd";
import {
  FlagOutlined,
  PieChartOutlined,
  UserOutlined,
  UnorderedListOutlined,
  BookOutlined,
  PlusCircleOutlined,
  PartitionOutlined,
  UnlockOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";

import logoLong from "./logo-enlong.png";
import logoCourt from "./logo-court.png";
import { getUser } from "./Api/Login.js";
import { GlobalContext } from "./GlobalContext";

import moment from "moment";
import "moment/locale/fr";
import frFR from "antd/lib/locale/fr_FR";

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

moment.locale("fr");

class AppLayout extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      user: null,
      openKeys: [""],
      redirection: false,
      hover: false,
      courseRedirection: false,
      courseId: null,
      locale: frFR,
    };

    this.onCollapse = this.onCollapse.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
  }

  /**
   *
   */
  async loadUser() {
    await getUser()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ user: data.user });
        this.context.updateGlobal({
          ...this.context.global,
          profile: data.user,
        });
      });
  }

  /**
   *
   */
  componentDidMount() {
    this.loadUser();
  }

  /**
   *
   * @param {*} collapsed
   */
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  /**
   *
   * @param {*} keys
   */
  onOpenChange = (keys) => {
    if (!this.state.collapsed) {
      let rootSubmenuKeys = [
        "sub1",
        "sub2",
        "sub3",
        "sub4",
        "sub5",
        "sub6",
        "sub7",
      ];
      const latestOpenKey = keys.find(
        (key) => this.state.openKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openkeys: keys });
      } else {
        this.setState({
          openKeys: latestOpenKey ? [latestOpenKey] : [],
        });
      }
    }
  };

  /**
   *
   */
  onClickDeconnexion() {
    localStorage.clear();
    this.setState({ redirection: true });
  }

  /**
   *
   */
  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }



  render() {
    const { collapsed } = this.state;
    const { children } = this.props;
    const { location } = this.props;
    const { redirection } = this.state;

    let logo = logoLong;

    if (redirection) {
      return <Redirect to="/login" />;
    }

    if (collapsed === true) {
      logo = logoCourt;
    }

    let name = null;

    if (this.state.user !== null) {
      name = this.state.user.name;
    }

    let buttonHover = null;
    if (this.state.hover) {
      buttonHover = <div className="logoutButton">Deconnexion</div>;
    }

    return (
      <ConfigProvider locale={this.state.locale}>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            width="250px"
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
          >
            <div className="logo">
              <img src={logo} className="logoImg" alt="logo" />
            </div>
            <Menu
              defaultSelectedKeys={[location.pathname]}
              mode="inline"
              openKeys={this.state.openKeys}
              onOpenChange={this.onOpenChange}
            >
              <Menu.Item key="/" icon={<PieChartOutlined />}>
                <Link to="/">DashBoard</Link>
              </Menu.Item>
              <SubMenu key="sub1" icon={<UserOutlined />} title="Utilisateurs">
                <Menu.Item key="/users">
                  <Link to="/users">
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/urbantztoken" icon={<UnlockOutlined />}>
                <Link to="/urbantztoken">Token Urbantz</Link>
              </Menu.Item>
              <Menu.Item key="/woop/add" icon={<FlagOutlined />}>
                <Link to="/woop/add">Woop</Link>
              </Menu.Item>
              <Menu.Item key="/deliveryoptions" icon={<ShoppingCartOutlined />}>
                <Link to="/deliveryoptions">Options de livraison</Link>
              </Menu.Item>
              <SubMenu key="sub2" icon={<BookOutlined />} title="Marques blanches">
                <Menu.Item key="/whitelabels">
                  <Link to="/whitelabels">
                    <UnorderedListOutlined /> Toutes
                  </Link>
                </Menu.Item>
                <Menu.Item key="/whitelabel/add">
                  <Link to="/whitelabel/add">
                    <PlusCircleOutlined /> Nouvelle marque blanche
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub3" icon={<PartitionOutlined />} title="Réseaux">
                <Menu.Item key="/networks">
                  <Link to="/networks">
                    {" "}
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/network/add">
                  <Link to="/network/add">
                    {" "}
                    <PlusCircleOutlined /> Nouveau réseau
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: "0" }}>
              <div className="avatar">
                <Button
                  type="text"
                  onClick={this.onClickDeconnexion}
                  onMouseEnter={this.toggleHover}
                  onMouseLeave={this.toggleHover}
                >
                  Bienvenue {name}
                  {buttonHover}
                </Button>
                <Avatar icon={<UserOutlined />} />
              </div>
            </Header>
            {children}
            <Footer style={{ textAlign: "center" }}></Footer>
          </Layout>
        </Layout>
      </ConfigProvider>
    );
  }
}
export default withRouter(AppLayout);
