import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyNetworks(data) {
    return fetch(config.hostApi + 'networks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getManyMerchants(data) {
    return fetch(config.hostApi + 'merchants', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function saveWoopConfiguration(data) {
    return fetch(config.hostApi + 'woop', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function saveWoopMerchant(id, data) {
    return fetch(config.hostApi + 'woop/' + id + '/merchant', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getWoopMerchants(id, data) {
    return fetch(config.hostApi + 'woop/' + id + '/merchants', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function deleteWoopMerchant(woopId, id) {
    return fetch(config.hostApi + 'woop/' + woopId + '/merchant/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getOneWoopMerchant(woopId, id) {
    return fetch(config.hostApi + 'woop/' + woopId + '/merchant/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function updateWoopMerchant(woopId, data) {
    return fetch(config.hostApi + 'woop/' + woopId + '/merchant/', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getWoopConfiguration(data) {
    return fetch(config.hostApi + 'woop/configuration', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}