import config from '../Config.js';

export function getManyMerchantThemes(data) {
    return fetch(config.hostApi + 'merchantthemes', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}