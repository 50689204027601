import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";

import { Table, Space, Button, Layout, Divider, PageHeader, Select } from 'antd';
import { UnorderedListOutlined, PlusCircleOutlined, FormOutlined } from '@ant-design/icons';

import { getManyNetworks } from '../../Api/Networks.js';
import { getManyWhiteLabels } from "../../Api/WhiteLabels.js";

class Networks extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            networks: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            data: [],
            value: null,
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: "Marque blanche",
                dataIndex: "white_label",
                key: "white_label",
                render: (white_label) => {
                    if (white_label == null) {
                        return <div></div>;
                    } else {
                        return <div>{white_label.name}</div>;
                    }
                },
            },
            {
                title: "Date de création",
                dataIndex: "created_at",
                key: "created_at",
                render: (created_at) => (
                    <div>
                        {moment(created_at, "YYYY-MM-DD hh:mm:ss").format(
                            "DD/MM/YYYY à hh:mm"
                        )}
                    </div>
                ),
            },
            {
                title: "",
                dataIndex: "id",
                key: "id",
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type="primary">
                            <Link to={"/network/" + id}>
                                <FormOutlined /> Editer
                            </Link>
                        </Button>
                    </Space>
                ),
            },
        ];

        this.handleSearch = this.handleSearch.bind(this);
        this.onClickWhiteLabelFilter = this.onClickWhiteLabelFilter.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadNetworks(params = {}) {
        this.setState({ loading: true });
        getManyNetworks(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    networks: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadNetworks({ pagination });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        this.loadNetworks({
            whiteLabel_id: this.state.value,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    /**
     * 
     */
    onClickWhiteLabelFilter() {
        const pagination = this.state.pagination;
        let value = this.state.value;
        let data = {
            pagination: pagination,
            whiteLabel_id: value
        }
        getManyNetworks(data)
            .then((res) => res.json())
            .then((json) => {
                console.log("onFinish", json);
                this.setState({
                    networks: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadNetworks({ pagination });
    }

    /**
     *
     * @param {*} value
     */
    handleSearch = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ data: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ value })
    }

    render() {
        const { networks, pagination, loading } = this.state;
        const { Content } = Layout;
        const { Option } = Select;


        return (
            <div>
                <PageHeader title="Réseaux" subTitle="Liste de vos réseaux" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}>
                            <UnorderedListOutlined /> LISTE
                        </h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to="/network/add">
                                        <PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter
                                    </Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider></Divider>
                        <div>
                            <Space>
                                <div>Marques blanches </div>
                                <Select
                                    style={{ width: 300 }}
                                    showSearch
                                    value={this.state.value}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearch}
                                    onChange={this.onChangeWhiteLabel}
                                    notFoundContent={null}
                                >
                                    {this.state.data.map((d) => (
                                        <Option key={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                                <Button type="primary" onClick={this.onClickWhiteLabelFilter}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                            <Divider></Divider>
                        </div>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={networks}
                        />
                    </div>
                </Content>
            </div>
        )
    }
}

export default Networks;