import React, { useEffect } from 'react';

import { Form, Input, Button, Space, Divider, Drawer, notification } from 'antd';

import { getDeliveryOption, saveDeliveryOption, updateDeliveryOption } from '../../Api/DeliveryOptions.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


/**
 * 
 * @param {*} props 
 * @returns 
 */
const DrawerDeliveryOptions = (props) => {
    let { id, showDeliveryOptionsDrawer, action } = props;

    const [form] = Form.useForm();

    let [visibleDeliveryOptionsDrawer, setVisibleDeliveryOptionsDrawer] = React.useState(false);
    let [option, setOption] = React.useState(null);
    let [inputPrice, setInputPrice] = React.useState(null);
    let [disable, setDisable] = React.useState(false);

    /**
     * 
     */
    const onCloseOptionsForm = () => {
        setVisibleDeliveryOptionsDrawer(false);
        form.resetFields();
        props.showDrawer(false);
    }

    /**
     * 
     */
    const fetchDeliveryOption = () => {
        let option_id = id;
        getDeliveryOption(option_id)
            .then(res => res.json())
            .then(json => {
                setOption(json);
                let optionName = null;
                if (json.option_name === null && json.parent !== null) {
                    optionName = json.parent.option_name;
                } else {
                    optionName = json.option_name
                }
                form.setFieldsValue({
                    option_name: optionName,
                    option_price: json.option_price,
                })
                if (json.is_admin === 0) {
                    setDisable(true);
                }
            })
    }

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newOption = option !== null ? { ...option } : {};

        newOption.option_name = values.option_name;
        newOption.option_price = values.option_price;

        if (action === 'save') {
            newOption.id = null;
            newOption.is_admin = true;
            saveDeliveryOption(newOption)
                .then(res => res.json())
                .then(() => {
                    notification['success']({
                        message: 'L\'option a bien été enregistrée'
                    });
                    setVisibleDeliveryOptionsDrawer(false);
                    props.showDrawer(false);
                })
        } else if (action === 'update') {
            newOption.id = option.id;
            newOption.is_admin = option.is_admin;
            updateDeliveryOption(newOption)
                .then(res => res.json())
                .then(() => {
                    notification['success']({
                        message: 'L\'option a bien été mise à jour'
                    });
                    setVisibleDeliveryOptionsDrawer(false);
                    props.showDrawer(false);
                })
        }
    };


    useEffect(() => {
        if (showDeliveryOptionsDrawer === true) {
            setInputPrice(null);
            setDisable(false);
            setVisibleDeliveryOptionsDrawer(true)
        }

        if (action === 'save') {
            form.resetFields();
        }

        if (id !== null && action === 'update') {
            fetchDeliveryOption()
        }


    }, [showDeliveryOptionsDrawer, id, action])

    return (
        <Drawer
            title="Création des options de livraison"
            width={720}
            onClose={onCloseOptionsForm}
            visible={visibleDeliveryOptionsDrawer}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>

                <Form.Item label="Nom " name="option_name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un nom.' }]}>
                    <Input disabled={disable} />
                </Form.Item>
                <Form.Item label="Prix " name="option_price">
                    <Input suffix="€" />
                </Form.Item>
                <Divider></Divider>
                <Form.Item>
                    <Space>
                        <Button onClick={onCloseOptionsForm} style={{ marginRight: 8 }}>
                            Annuler
                        </Button>
                        <Button htmlType="submit" type="primary">
                            Enregistrer
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default DrawerDeliveryOptions;