import React from 'react';
import { useHistory } from 'react-router-dom';

import { Form, Input, Button, message } from 'antd';

import loginFetch from '../../Api/Login.js'

const layout = {
    labelCol: { span: 20 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 0, span: 6 },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormLogin = (props) => {

    let history = useHistory();

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        loginFetch(values)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                if (data.error !== "invalid_credentials") {
                    localStorage.setItem('token', data);
                    history.push("/");
                } else {
                    message.error('Identifiants de connexion invalides');
                    history.push("/login")
                }
            })
    }
    return (
        <Form {...layout} onFinish={onFinish} >
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your username!' }]} >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]} >
                <Input.Password placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    CONNEXION
                </Button>
            </Form.Item>
        </Form>
    )
};
export default FormLogin;