import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export default function loginFetch(data) {
    return fetch(config.hostApi + 'authenticate', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @returns 
 */
export function getUser() {
    return fetch(config.hostApi + 'user', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @returns 
 */
export function getOneUser(id) {
    return fetch(config.hostApi + 'user/' + id, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}