import React from 'react';

import { Layout } from 'antd';

class Dashboard extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {

        }

    }



    render() {
        const { Content } = Layout;

        return (
            <Content>
                <div>Hello...</div>
            </Content>
        );
    }
}

export default Dashboard;