import React from 'react';

import { Layout, Divider, notification, PageHeader } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getOneNetwork, updateNetwork, getNetworkOpeningSchedule, getBoundaries } from '../../Api/Networks.js';
import FormNetwork from './FormNetwork.js';

class NetworkEdit extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            network: null,
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     */
     loadOneNetwork() {
        const id = this.props.match.params.id;
        getOneNetwork(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    network: data.network
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadOneNetwork();
    }

    /**
     * 
     * @param {*} values 
     */
     onSubmit(values) {
        const networkId = this.props.match.params.id;
        let data = {
            id: networkId,
            name: values.name,
            whiteLabel_id: values.whiteLabel_id,
            price_grid: values.price_grid,
            detailed_prices: values.detailed_prices,
            phone: values.phone,
            color: values.color,
            logo: values.logo
        }
        if (values.fixed_price !== null) {
            data.fixed_price = values.fixed_price.price;
            if (values.fixed_price.id !== null) {
                data.price_id = values.fixed_price.id;
            }
        }
        if (values.configuration !== null) {
            data.max_distance = values.configuration.max_distance;
            data.max_weigth = values.configuration.max_weigth;
            data.slack_webhook = values.configuration.slack_webhook;
        }
        if (values.commission !== null) {
            data.commission_price = values.commission.commission_price
        }
        updateNetwork(networkId, data)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le réseau bien mis à jour'
                })
                this.loadOneNetwork();
            })
    };

    render() {
        const { Content } = Layout;

        return (
            <div>
                <PageHeader title="Réseaux" subTitle="Edition d'un réseau" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormNetwork network={this.state.network} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default NetworkEdit;