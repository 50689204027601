import React from "react";

import { PageHeader, Table, Space, Button, Layout, Drawer, Input, Divider, Select, Form, Radio, notification, Popconfirm, message } from "antd";
import {
    UnorderedListOutlined,
    EyeOutlined,
    CloseOutlined
} from "@ant-design/icons";

import { getManyUsers, getOneUser, updateUser, deleteUser } from "../../Api/Users.js";
import { getManyWhiteLabels } from "../../Api/WhiteLabels.js";
import { GlobalContext } from '../../GlobalContext.js';

const layoutGeneral = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

class Users extends React.Component {
    static contextType = GlobalContext;
    formRef = React.createRef();

    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleDrawerUserInfo: false,
            user: null,
            user_name: null,
            dataWhiteLabel: [],
            whiteLabel_id: null,
            isAdmin: 0
        };

        this.columns = [
            {
                title: "#",
                dataIndex: "id",
                key: "id",
                width: 50
            },
            {
                title: "Nom",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (type) => {
                    const translate = {
                        driver: 'Coursier',
                        merchant: 'Commerçant'
                    }
                    return (
                        <div key={type}>
                            {translate[type]}
                        </div>
                    )
                }
            },
            {
                title: "Marque blanche",
                dataIndex: "white_label",
                key: "white_label",
                render: (white_label) => {
                    if (white_label == null) {
                        return <div></div>;
                    } else {
                        return <div>{white_label.name}</div>;
                    }
                },
            },
            {
                title: "Admin",
                dataIndex: "is_admin",
                key: "is_admin",
                render: (is_admin) => {
                    if (is_admin === 0) {
                        return <div>NON</div>;
                    } else {
                        return <div>OUI</div>;
                    }
                },
            },
            {
                title: "",
                dataIndex: "id",
                key: "id",
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type='primary' onClick={() => this.showDrawerUserInfo(id)}>
                            <EyeOutlined /> Aperçu
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer cet utilisateur ?"
                            onConfirm={(e) => this.confirmDeleteUser(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <CloseOutlined /> Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        this.onCloseUserInfo = this.onCloseUserInfo.bind(this);
        this.showDrawerUserInfo = this.showDrawerUserInfo.bind(this);
        this.onClickFilterUsers = this.onClickFilterUsers.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.onSearchUserName = this.onSearchUserName.bind(this);
        this.handleSearchWhiteLabel = this.handleSearchWhiteLabel.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    }

    /**
     *
     * @param {*} params
     */
    loadUsers(params = {}) {
        this.setState({ loading: true });
        getManyUsers(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    users: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadUsers({ pagination });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        let data = {
            whiteLabel_id: this.state.whiteLabel_id,
            term: this.state.merchant_name,
            pagination,
        }
        getManyUsers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    users: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                });
            });
    };

    /**
     *
     * @param {*} id
     */
    showDrawerUserInfo(id) {
        getOneUser(id)
            .then((res) => res.json())
            .then((json) => {
                const translate = {
                    driver: 'Coursier',
                    merchant: 'Commerçant'
                }
                this.setState({
                    user: json.user,
                    isAdmin: json.user.is_admin,
                    visibleDrawerUserInfo: true
                })
                let whiteLabel_name = null;
                if (json.user.white_label !== null) {
                    whiteLabel_name = json.user.white_label.name
                }
                this.formRef.current.setFieldsValue({
                    name: json.user.name,
                    is_admin: json.user.is_admin,
                    email: json.user.email,
                    type: translate[json.user.type],
                    white_label: whiteLabel_name
                })
            })
    }

    /**
     *
     */
    onCloseUserInfo() {
        this.setState({
            visibleDrawerUserInfo: false
        })
    }

    /**
    * 
    */
    onClickFilterUsers() {
        const pagination = this.state.pagination;
        let user_name = this.state.user_name;
        let whiteLabel_id = this.state.whiteLabel_id;
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            term: user_name
        }
        getManyUsers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    users: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            whiteLabel_id: null,
            user_name: null
        })
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadUsers({ pagination });
    }

    /**
    *
    * @param {*} e
    */
    onSearchUserName = (e) => {
        if (e.target.value.length > 2) {
            this.setState({ user_name: e.target.value })
        }
    };

    /**
     *
     * @param {*} value
     */
    handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataWhiteLabel: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ whiteLabel_id: value })
    }

    onFinish = (values) => {
        let user = this.state.user;
        let userId = user.id;
        let password = null;
        let confirmation_password = null;
        if (values.password === undefined) {
            password = '';
        } else {
            password = values.password;
        }
        if (values.confirmation_password === undefined) {
            confirmation_password = '';
        } else {
            confirmation_password = values.confirmation_password
        }
        let data = {
            name: values.name,
            email: values.email,
            password: password,
            confirmation_password: confirmation_password,
            is_admin: values.is_admin
        }
        updateUser(userId, data)
            .then((res) => res.json())
            .then((json) => {
                let pagination = this.state.pagination;
                notification['success']({
                    message: 'L\'utilisateur a bien été mis à jour'
                })
                this.setState({
                    visibleDrawerUserInfo: false
                });
                this.loadUsers(pagination);
            })
    };

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    confirmDeleteUser(id, e) {
        e.preventDefault();
        deleteUser(id)
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error('Something went wrong');
                }

            }).then(() => {
                notification['success']({
                    message: 'L\'utilisateur a bien été supprimé.'
                })
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                this.loadUsers({ pagination });
            }).catch(() => {
                notification['error']({
                    message: 'L\'utilisateur ne peut être supprimé. Il est utilisé dans des courses.'
                })
            })
    }

    render() {
        const { users, pagination, loading } = this.state;
        const { Content } = Layout;
        const { Option } = Select;
        let isAdmin = this.state.isAdmin;


        return (
            <div>
                <PageHeader title="Utilisateurs" subTitle="Liste de vos utilisateurs" />
                <Content style={{ margin: "0 16px 16px 16px" }}>
                    <div
                        className="site-layout-background"
                        style={{ padding: 24, minHeight: 360 }}
                    >
                        <h2 style={{ color: "#2FAC66" }}>
                            <UnorderedListOutlined /> LISTE
                        </h2>
                        <Divider></Divider>
                        <Space direction="vertical">
                            <Space>
                                <div style={{ width: '120px', marginBottom: '18px' }}>Marques blanches </div>
                                <Select
                                    style={{ width: 300, marginBottom: '18px' }}
                                    showSearch
                                    value={this.state.whiteLabel_id}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchWhiteLabel}
                                    onChange={this.onChangeWhiteLabel}
                                    notFoundContent={null}
                                >
                                    {this.state.dataWhiteLabel.map((d) => (
                                        <Option key={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space>
                                <div style={{ width: '120px', marginBottom: '18px' }}>Nom </div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchUserName}
                                    style={{ width: 300, marginBottom: '18px' }}
                                />
                            </Space>
                            <Space>
                                <Button type="primary" onClick={this.onClickFilterUsers} style={{ marginLeft: '128px' }}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider></Divider>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={users}
                        />
                    </div>
                </Content>
                <Drawer
                    title="Informations de l'utilisateur"
                    placement="right"
                    closable={false}
                    onClose={this.onCloseUserInfo}
                    visible={this.state.visibleDrawerUserInfo}
                    width={720}
                >
                    <div>
                        <Form ref={this.formRef} onFinish={this.onFinish} {...layoutGeneral}>
                            <Form.Item label="Nom" name="name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer votre Prénom.' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Email" name="email" hasFeedback rules={[{ type: 'email', required: true, message: 'Merci d\'indiquer votre email.' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Mot de passe" name="password" >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Confirmation du mot de passe"
                                name="confirmation_password"
                                dependencies={['password']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Les 2 mots de passe ne correspondent pas'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="is_admin"
                                label="Admin"
                                hasFeedback
                                rules={[{ required: true, message: 'Merci de choisir une option.' }]}
                            >
                                <Radio.Group defaultValue={isAdmin}>
                                    <Radio value={0}>Non</Radio>
                                    <Radio value={1}>Oui</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Marque Blanche" name="white_label" >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item label="Type" name="type" >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item >
                                <Space>
                                    <Button type="primary" htmlType="submit">
                                        Enregistrer
                                    </Button>
                                    <Button onClick={this.onCloseUserInfo} style={{ marginRight: 8 }}>
                                        Annuler
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default Users;
