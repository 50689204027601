import React, { useEffect } from 'react';

import { notification, Input, Divider, Space, Button, Table, Form, Popconfirm, Select, Drawer } from 'antd';
import { FormOutlined, CloseOutlined } from '@ant-design/icons';

import {
    getManyMerchants, getWoopMerchants, saveWoopMerchant, deleteWoopMerchant,
    getOneWoopMerchant, updateWoopMerchant
} from '../../Api/Woop.js';

const { Option } = Select;
/**
 * 
 * @param {*} param0 
 * @returns 
 */
const DrawerAddMerchant = ({ visible, onSubmit, onClose, networkId, merchant }) => {
    const [form] = Form.useForm();

    const [valueMerchant, setValueMerchant] = React.useState(undefined);
    let [dataMerchant, setDataMerchant] = React.useState([]);

    const optionMerchant = dataMerchant.map(d => <Option key={d.id}>{d.name}</Option>);

    /**
     * 
     * @param {*} value 
     */
    const handleSearchMerchant = (value) => {
        if (value.length > 2) {
            let data = {
                term: value,
                network_id: networkId
            };
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchant(dataMerchant = json.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeMerchant = (values) => {
        setValueMerchant(values.value);

    }

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        onSubmit(values)
        form.resetFields();
    }

    /**
     * 
     */
    useEffect(() => {
        let formValue = {
            merchant_id: {
                key: null,
                value: null,
                label: null
            },
            woop_merchant_name: null
        }

        if (merchant !== null) {
            formValue.merchant_id = {
                key: merchant.merchant_id,
                value: merchant.merchant_id,
                label: merchant.merchant.name
            }
            formValue.woop_merchant_name = merchant.woop_merchant_name
        }

        form.setFieldsValue(formValue);
    }, [merchant])

    return (
        <Drawer
            title="Ajouter un nouveau commerçant"
            width={720}
            onClose={onClose}
            visible={visible}

        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="merchant_id"
                    label="Commercant"
                >
                    <Select
                        labelInValue={true}
                        showSearch
                        value={valueMerchant}
                        placeholder="Entrer au minimum 3 caractères"
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleSearchMerchant}
                        onChange={handleChangeMerchant}
                        notFoundContent={null}
                        allowClear
                    >
                        {optionMerchant}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="woop_merchant_name"
                    label="Nom du commerçant Woop"
                >
                    <Input />
                </Form.Item>
                <Divider></Divider>
                <Form.Item >
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                        <Button htmlType="button" onClick={onClose}>
                            Annuler
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const SettingsMerchant = (props) => {

    let { networkId, woop } = props

    let [merchants, setMerchants] = React.useState([]);
    let [loadingMerchants, setLoadingMerchants] = React.useState(false);
    let [visible, setVisible] = React.useState(false);
    let [pagination, setPagination] = React.useState({ current: 1, pageSize: 10, })
    let [merchant, setMerchant] = React.useState(null);

    const columns = [
        {
            title: 'Commerçant Applicolis',
            dataIndex: 'merchant',
            key: 'merchant',
            render: (merchant) => {
                if (merchant == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <div>{merchant.name}</div>
                    )
                }
            }
        },
        {
            title: 'Identifiant Woop du commerçant',
            dataIndex: 'woop_merchant_name',
            key: 'woop_merchant_name',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (id) => {
                return (
                    <Space size="middle">
                        <Button type='primary' onClick={(e) => editTag(id, e)}>
                            <FormOutlined /> Editer
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer ce tag ?"
                            onConfirm={(e) => confirmDeleteTag(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <CloseOutlined /> Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>);
            }
        }
    ]

    /**
     * 
     * @param {*} params 
     */
    const loadMerchants = (id, params = {}) => {
        setLoadingMerchants(true);
        getWoopMerchants(id, params)
            .then((res) => res.json())
            .then((json) => {
                setLoadingMerchants(false);
                setMerchants(json.results);
                setPagination({
                    ...params.pagination,
                    total: json.totalCount,
                })
            })
    }


    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const confirmDeleteTag = (id, e) => {
        let woopId = woop.id;
        e.preventDefault();
        deleteWoopMerchant(woopId, id)
            .then((res) => res.json())
            .then(() => {
                notification['success']({
                    message: 'Le commerçant a bien été supprimé de la configuration Woop'
                })
                loadMerchants(woopId, { pagination })
            })
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const editTag = (id, e) => {
        let woopId = woop.id;
        e.preventDefault();
        getOneWoopMerchant(woopId, id)
            .then(res => res.json())
            .then((json) => {
                setMerchant(json);
                setVisible(true);
            })
    }

    /**
     * 
     */
    const onClose = () => {
        setVisible(false);
        setMerchant(null);
    }

    /**
     * 
     * @param {*} values 
     */
    const onSubmit = (values) => {
        let data = {
            network_id: networkId,
            merchant_id: values.merchant_id.value,
            woop_merchant_name: values.woop_merchant_name
        }
        let woopId = woop.id;
        if (merchant !== null) {
            let merchantId = merchant.id;
            data.id = merchantId;
            updateWoopMerchant(woopId, data)
                .then(res => res.json())
                .then((data) => {
                    notification['success']({
                        message: 'Le commerçant a bien été mis à jour dans la configuration Woop'
                    })
                    setMerchant(null);
                    loadMerchants(woopId, { pagination })
                })
        } else {
            saveWoopMerchant(woopId, data)
                .then(res => res.json())
                .then((data) => {
                    notification['success']({
                        message: 'Le commerçant a bien été enregistré dans la configuration Woop'
                    })
                    loadMerchants(woopId, { pagination })
                })
        }
        setVisible(false)
    }

    /**
     * 
     */
    useEffect(() => {
        if (woop !== null) {
            let woopId = woop.id;
            loadMerchants(woopId, { pagination })
        }
    }, [woop, merchant])

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={() => { setVisible(true); }}>
                    Ajouter un commerçant
                </Button>
                <Divider></Divider>
                <Table columns={columns} dataSource={merchants} loading={loadingMerchants} pagination={pagination} />
                <DrawerAddMerchant visible={visible} onSubmit={onSubmit} onClose={onClose} networkId={networkId} merchant={merchant} />
            </div>
        </div>
    );
}

export default SettingsMerchant;