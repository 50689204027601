import React, { useEffect } from 'react';

import { Button, Space, Table, Divider, notification, Popconfirm, Layout, PageHeader, Select } from 'antd';
import { EyeOutlined, DeleteOutlined, ArrowRightOutlined } from '@ant-design/icons';

import DrawerDeliveryOptions from '../../Components/DeliveryOptions/DrawerDeliveryOptions.js';

import { deleteDeliveryOption, getDeliveryOptions } from '../../Api/DeliveryOptions.js';
import { getManyNetworks } from '../../Api/Networks.js';
import { getManyWhiteLabels } from '../../Api/WhiteLabels.js';
import { getManyMerchantThemes } from '../../Api/MerchantThemes.js';
import { getManyMerchants } from '../../Api/Merchants.js';

const { Content } = Layout;
const { Option } = Select;
/**
 * 
 * @param {*} props 
 * @returns 
 */
const DeliveryOptions = (props) => {

    let [loading, setLoading] = React.useState(false);
    let [visibleDeliveryOptionsDrawer, setVisibleDeliveryOptionsDrawer] = React.useState(false);
    let [options, setOptions] = React.useState([]);
    let [id, setId] = React.useState(null);
    let [actionOption, setActionOption] = React.useState('save');
    let [networkId, setNetworkId] = React.useState(null);
    let [dataNetwork, setDataNetwork] = React.useState([]);
    let [whiteLabelId, setWhiteLabelId] = React.useState(null);
    let [dataWhiteLabel, setDataWhiteLabel] = React.useState([]);
    let [merchantthemeId, setMerchantthemeId] = React.useState(null);
    let [dataMerchanttheme, setDataMerchanttheme] = React.useState([]);
    let [merchantId, setMerchantId] = React.useState(null);
    let [dataMerchant, setDataMerchant] = React.useState([]);

    const columns = [
        {
            title: 'Nom',
            dataIndex: '',
            key: 'option_name',
            render: (row) => {
                if (row.option_name === null && row.parent !== null) {
                    return (
                        <div>
                            {row.parent.option_name}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            {row.option_name}
                        </div>
                    )
                }
            }
        },
        {
            title: 'Prix',
            dataIndex: 'option_price',
            key: 'option_price',
        },
        {
            title: 'Admin',
            dataIndex: 'is_admin',
            key: 'is_admin',
            render: (is_admin) => {
                if (is_admin === 0) {
                    return <div>NON</div>;
                } else {
                    return <div>OUI</div>;
                }
            },
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 250,
            render: (id) => (
                <div>
                    <Space wrap>
                        <Button type='primary' onClick={(e) => onClickEditOption(id, e)}>
                            <EyeOutlined />
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer cet entrepôt ?"
                            onConfirm={(e) => onClickDeleteOption(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Space>
                </div>
            )
        },
    ];

    /**
     * 
     */
    const showDeliveryOptionsDrawer = () => {
        setVisibleDeliveryOptionsDrawer(true);
    }

    /**
     *
     * @param {*} value
     */
    const showDrawer = (value) => {
        fetchDeliveryOptions(networkId);
        setVisibleDeliveryOptionsDrawer(value);
        setActionOption('save');
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    const onClickDeleteOption = (id, e) => {
        e.preventDefault();
        deleteDeliveryOption(id)
            .then(res => res.json())
            .then(() => {
                notification['success']({
                    message: 'L\'option a bien été supprimée'
                });
                fetchDeliveryOptions();
            })
    }

    /**
     * 
     */
    const onClickEditOption = (id) => {
        setId(id);
        setActionOption('update');
        setVisibleDeliveryOptionsDrawer(true);
    }

    /**
     * 
     * @param {*} networkId 
     */
    const fetchDeliveryOptions = async (networkId) => {
        let data = {
            network_id: networkId
        }
        getDeliveryOptions(data)
            .then(res => res.json())
            .then(json => {
                setOptions(json);
            })

    }

    /**
     *
     * @param {*} value
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataWhiteLabel(json.results);
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const onChangeWhiteLabel = (value) => {
        setWhiteLabelId(value);
    }

    /**
     *
     * @param {*} value
     */
    const handleSearchNetwork = (value) => {       
        if (value.length > 2) {
            getManyNetworks({
                whiteLabel_id: whiteLabelId,
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataNetwork(json.results);
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const onChangeNetwork = (value) => {
        setNetworkId(value);
    }

    /**
     *
     * @param {*} value
     */
     const handleSearchMerchanttheme = (value) => {       
        if (value.length > 2) {
            getManyMerchantThemes({
                whiteLabel_id: whiteLabelId,
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchanttheme(json.results);
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const onChangeMerchanttheme = (value) => {
        setMerchantthemeId(value);
    }

    /**
     *
     * @param {*} value
     */
     const handleSearchMerchant = (value) => {       
        if (value.length > 2) {
            getManyMerchants({
                whiteLabel_id: whiteLabelId,
                network_id: networkId,
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchant(json.results);
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const onChangeMerchant = (value) => {
        setMerchantId(value);
    }

    /**
     * 
     */
    const onClickFilter = () => {
        let data = {
            network_id: networkId,
            merchanttheme_id: merchantthemeId,
            merchant_id: merchantId
        }
        getDeliveryOptions(data)
            .then(res => res.json())
            .then(json => {
                setOptions(json);
            })
    }

    /**
     * 
     */
    const onClickDeleteFilter = () => {
        setWhiteLabelId(null);
        setNetworkId(null);
        setMerchantId(null);
        setMerchantthemeId(null);
        fetchDeliveryOptions(null);
    }

    useEffect(() => {
        fetchDeliveryOptions();
    }, []);

    return (
        <div>
            <PageHeader title="Options de livraison" />
            <Content style={{ margin: "0 16px 16px 16px" }}>
                <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                >
                    <h2>Liste des options de livraison</h2>
                    <Space direction="vertical">
                        <Space>
                            <div className="filter-title">Marques blanches </div>
                            <Select
                                className="filter"
                                style={{ width: 300 }}
                                showSearch
                                value={whiteLabelId}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchWhiteLabel}
                                onChange={onChangeWhiteLabel}
                                notFoundContent={null}
                            >
                                {dataWhiteLabel.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <div className="filter-title">Réseaux </div>
                            <Select
                                className="filter"
                                showSearch
                                value={networkId}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchNetwork}
                                onChange={onChangeNetwork}
                                notFoundContent={null}
                            >
                                {dataNetwork.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <div className="filter-title">Thème commerçant </div>
                            <Select
                                className="filter"
                                showSearch
                                value={merchantthemeId}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchMerchanttheme}
                                onChange={onChangeMerchanttheme}
                                notFoundContent={null}
                            >
                                {dataMerchanttheme.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <div className="filter-title">Commerçant </div>
                            <Select
                                className="filter"
                                showSearch
                                value={merchantId}
                                placeholder="Entrer au minimum 3 caractères"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchMerchant}
                                onChange={onChangeMerchant}
                                notFoundContent={null}
                            >
                                {dataMerchant.map((d) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space>
                            <Button className="filter-button" type="primary" onClick={onClickFilter}>
                                Filtrer
                            </Button>
                            <Button style={{ backgroundcolor: "#2FAC66" }} onClick={onClickDeleteFilter}>
                                Annuler
                            </Button>
                        </Space>
                    </Space>
                    <Divider></Divider>
                    <Table
                        columns={columns}
                        loading={loading}
                        dataSource={options}
                    />
                    <Divider></Divider>
                    <Button type='primary' onClick={showDeliveryOptionsDrawer}>Ajouter une option</Button>
                    <DrawerDeliveryOptions id={id} showDeliveryOptionsDrawer={visibleDeliveryOptionsDrawer} showDrawer={showDrawer} action={actionOption} />
                </div>
            </Content>
        </div>
    )
}

export default DeliveryOptions;