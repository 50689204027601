import React, { useEffect } from 'react';

import { Tabs, Alert } from 'antd';
import { InfoCircleOutlined, BuildOutlined } from '@ant-design/icons';

import WoopMerchantAddTab from './WoopMerchantAddTab.js';
import WoopNetworkConfigTab from './WoopNetworkConfigTab.js';

const { TabPane } = Tabs;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const WoopForm = (props) => {

    let { networkId, woop } = props;

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    /**
     * 
     */
    useEffect(() => {
    }, [networkId, woop])

    let woopForm = null;
    let woopFormMerchant = null;

    if (networkId) {
        woopForm = (
            <WoopNetworkConfigTab networkId={networkId} woop={woop} formSubmit={onFinish} />
        )
    } else {
        woopForm = (
            <Alert message="Vous devez sélectionner un réseau pour configurer Woop." type="success" />
        )
    }

    if (woop) {
        woopFormMerchant = (
            <WoopMerchantAddTab networkId={networkId} woop={woop} formSubmit={onFinish} />
        )
    } else {
        woopFormMerchant = (
            <Alert message="Vous devez enregistrer une configuration Woop avant de pouvoir associer des commerçants." type="success" />
        )
    }

    return (
        <div>
            <Tabs defaultActiveKey="1" type="card" >
                <TabPane tab={<span><InfoCircleOutlined />Configuration du Réseau</span>} key="1">
                    {woopForm}
                </TabPane>
                <TabPane tab={<span><BuildOutlined />Configuration des commerçants</span>} key="2">
                    {woopFormMerchant}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default WoopForm;