import React, { useEffect } from 'react';

import { Form, Input, Button, Divider, Radio, Space } from 'antd';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const WoopNetworkConfigTab = (props) => {
    const [form] = Form.useForm();

    let { networkId, woop } = props;

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let data = {
            network_id: networkId,
            env: values.env,
            staging_client_id: values.staging_client_id,
            staging_client_secret: values.staging_client_secret,
            production_client_id: values.production_client_id,
            production_client_secret: values.production_client_secret
        };

        props.formSubmit(data);
    };

    /**
     * 
     */
    const onReset = () => {
        form.resetFields();
    };

    /**
     * 
     */
    useEffect(() => {
        let formValues = {
            env: "staging",
            staging_client_id: null,
            staging_client_secret: null,
            production_client_id: null,
            production_client_secret: null
        }

        if (woop !== null) {
            formValues.env = woop.env;
            formValues.staging_client_id = woop.staging_client_id;
            formValues.staging_client_secret = woop.staging_client_secret;
            formValues.production_client_id = woop.production_client_id;
            formValues.production_client_secret = woop.production_client_secret;

        }

        form.setFieldsValue(formValues);
    }, [woop])

    return (
        <div>
            <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item
                    name="env"
                    label="Environnement"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="staging">Test</Radio>
                        <Radio value="production">Production</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider orientation="left">Environnement de Test</Divider>
                <Form.Item
                    name="staging_client_id"
                    label="Identifiant du client"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="staging_client_secret"
                    label="Clé secrète du client"
                >
                    <Input />
                </Form.Item>
                <Divider orientation="left">Environnement de Production</Divider>
                <Form.Item
                    name="production_client_id"
                    label="Identifiant du client"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="production_client_secret"
                    label="Clé secrète du client"
                >
                    <Input />
                </Form.Item>
                <Divider></Divider>
                <Form.Item >
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                        <Button htmlType="button" onClick={onReset}>
                            Annuler
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default WoopNetworkConfigTab;