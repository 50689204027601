import React from 'react';

import { PageHeader, Table, Space, Button, Layout, Drawer, Input, Divider, Select, Form, message, notification, Popconfirm } from "antd";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { getMerchantsWithUrbantzTokens, getManyNetworks, getManyMerchants, saveUrbantzToken, updateUrbantzToken, getOneMerchantWithUrbantzTokens, getOneNetwork, deleteUrbantzTokens } from '../../Api/UrbantzTokens';
import { GlobalContext } from '../../GlobalContext.js';

const layoutGeneral = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

class UrbantzToken extends React.Component {
    static contextType = GlobalContext;
    formRef = React.createRef();

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            merchants: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            showDrawer: false,
            dataNetwork: [],
            network: null,
            merchant: null,
            dataMerchant: [],
            mode: 'save',
            merchant_id: null,
            merchant_name: null,
            token: null
        }

        this.columns = [
            {
                title: "Commerçant",
                dataIndex: "merchant",
                key: "merchant",
                render: (merchant) => (
                    <div>{merchant.name}</div>
                )
            },
            {
                title: "Token",
                dataIndex: "urbantz_token",
                key: "urbantz_token",
            },
            {
                title: "",
                dataIndex: "merchant",
                key: "merchant_id",
                width: 100,
                render: (merchant) => (
                    <Space size="middle">
                        <Button type='primary' onClick={() => this.showDrawertoken(merchant)}>
                            <EyeOutlined /> Editer
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer ce token ?"
                            onConfirm={(e) => this.confirmDeleteToken(merchant, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <CloseOutlined /> Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        this.handleTableChange = this.handleTableChange.bind(this);
        this.onClickOpenDrawerAddToken = this.onClickOpenDrawerAddToken.bind(this);
        this.onCloseDrawerToken = this.onCloseDrawerToken.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.handleSearchNetwork = this.handleSearchNetwork.bind(this);
        this.handleChangeNetwork = this.handleChangeNetwork.bind(this);
        this.handleSearchMerchant = this.handleSearchMerchant.bind(this);
        this.handleChangeMerchant = this.handleChangeMerchant.bind(this);
        this.confirmDeleteToken = this.confirmDeleteToken.bind(this);
        this.onClickFilterMerchants = this.onClickFilterMerchants.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.onSearchMerchantName = this.onSearchMerchantName.bind(this);
        this.onsearchToken = this.onsearchToken.bind(this);
    }

    /**
     * 
     */
    async loadMerchants() {
        this.setState({ loading: true })
        let pagination = this.state.pagination;
        await getMerchantsWithUrbantzTokens(pagination)
            .then(res => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    merchants: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadMerchants();
    }

    /**
     * 
     * @param {*} merchant 
     */
    async showDrawertoken(merchant) {
        this.setState({
            merchant_id: merchant.id,
            showDrawer: true,
            mode: 'edit'
        });
        await getOneNetwork(merchant.network_id)
            .then(res => res.json())
            .then((json) => {
                this.setState({ network: json.network.id })
                this.formRef.current.setFieldsValue({
                    network_id: {
                        key: json.network.id,
                        value: json.network.id,
                        label: json.network.name
                    }
                })
            })
        await getOneMerchantWithUrbantzTokens(merchant.id)
            .then(res => res.json())
            .then((json) => {
                this.formRef.current.setFieldsValue({
                    merchant_id: {
                        key: merchant.id,
                        value: merchant.id,
                        label: merchant.name
                    },
                    urbantz_token: json.urbantz_token
                })
            })
    }

    /**
    *
    * @param {*} pagination
    * @param {*} filters
    * @param {*} sorter
    */
    handleTableChange = (pagination, filters, sorter) => {
        let data = {
            pagination
        }
        getMerchantsWithUrbantzTokens(data)
            .then(res => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    merchants: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    onClickOpenDrawerAddToken = () => {
        this.setState({
            mode: 'save',
            showDrawer: true
        });
        if (this.formRef.current !== null) {
            this.formRef.current.resetFields();
        }

    }

    /**
     * 
     */
    onCloseDrawerToken() {
        this.setState({
            showDrawer: false
        })
    }

    /**
     * 
     * @param {*} value 
     */
    handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value,
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataNetwork: json.results });
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    handleChangeNetwork = (values) => {
        if (values !== undefined) {
            this.setState({ network: values.value })
        }
    }

    /**
    * 
    * @param {*} value 
    */
    handleSearchMerchant = (value) => {
        if (value.length > 2) {
            let networkId = this.state.network;
            let data = {
                term: value,
                network_id: networkId
            };
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataMerchant: json.results });
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    handleChangeMerchant = (values) => {
        this.setState({ merchant: values.value });
    }

    /**
     * 
     * @param {*} values 
     */
    onFinish(values) {
        const messages = {
            301: 'Le commerçant a déjà un token Urbantz.',
            302: 'Le token Urbantz est déjà attribué'
        }
        let mode = this.state.mode;
        if (mode === 'save') {
            let data = {
                merchant_id: parseInt(values.merchant_id.value),
                urbantz_token: values.urbantz_token
            }
            saveUrbantzToken(data)
                .then(res => res.json())
                .then((json) => {
                    let errors = json.errors;
                    if (errors.length > 0) {
                        errors.map((error) => {
                            if (error === 301) {
                                message.open({
                                    type: 'error',
                                    content: <p>{messages[301]}</p>,
                                });
                            } else if (error === 302) {
                                message.open({
                                    type: 'error',
                                    content: <p>{messages[302]}</p>,
                                });
                            }
                        })
                    } else {
                        notification['success']({
                            message: 'Le token a bien été enregistré'
                        })
                    }
                    this.setState({
                        showDrawer: false
                    });
                    this.loadMerchants();
                })
        } else {
            let merchant_id = parseInt(values.merchant_id.value)
            let data = {
                urbantz_token: values.urbantz_token
            }
            updateUrbantzToken(merchant_id, data)
                .then(res => res.json())
                .then((json) => {
                    let error = json.errors;
                    if (error !== null) {
                        message.open({
                            type: 'error',
                            content: <p>{messages[error]}</p>,
                        });
                    } else {
                        notification['success']({
                            message: 'Le token a bien été mis à jour'
                        })
                    }
                    this.setState({
                        showDrawer: false
                    });
                    this.loadMerchants();
                })
        }
    }

    /**
     * 
     * @param {*} merchant 
     * @param {*} e 
     */
    confirmDeleteToken(merchant, e) {
        e.preventDefault();
        deleteUrbantzTokens(merchant.id)
            .then(res => res.json())
            .then(() => {
                notification['success']({
                    message: 'Le token a bien été supprimé'
                })
                this.setState({
                    showDrawer: false
                });
                this.loadMerchants();
            })
    }

    /**
    * 
    */
    onClickFilterMerchants() {
        const pagination = this.state.pagination;
        let merchant_name = this.state.merchant_name;
        let token = this.state.token;
        let data = {
            pagination: pagination,
            term: merchant_name,
            token: token
        }
        getMerchantsWithUrbantzTokens(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    merchants: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            token: null,
            merchant_name: null
        })
        this.loadMerchants();
    }

    /**
     *
     * @param {*} e
     */
    onSearchMerchantName = (e) => {
        if (e.target.value.length > 2) {
            this.setState({ merchant_name: e.target.value })
        }
    };

    /**
     *
     * @param {*} e
     */
    onsearchToken = (e) => {
        if (e.target.value.length > 2) {
            this.setState({ token: e.target.value })
        }
    };

    render() {
        const { Content } = Layout;
        const { Option } = Select;
        const { merchants, pagination, loading } = this.state;
        const optionNetwork = this.state.dataNetwork.map(d => <Option key={d.id}>{d.name}</Option>);
        const optionMerchant = this.state.dataMerchant.map(d => <Option key={d.id}>{d.name}</Option>);

        let disable = this.state.network !== null ? false : true;

        return (
            <div>
                <PageHeader title="Tokens Urbantz" />
                <Content style={{ margin: "0 16px 16px 16px" }}>
                    <div
                        className="site-layout-background"
                        style={{ padding: 24, minHeight: 360 }}
                    >
                        <Space direction="vertical">
                            <Space>
                                <div style={{ width: '150px', marginBottom: '18px' }}>Token </div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onsearchToken}
                                    style={{ width: 300 , marginBottom: '18px'}}
                                />
                            </Space>
                            <Space>
                                <div style={{ width: '150px', marginBottom: '18px' }}>Nom du commerçant </div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchMerchantName}
                                    style={{ width: 300, marginBottom: '18px' }}
                                />
                            </Space>
                            <Space>
                                <Button type="primary" onClick={this.onClickFilterMerchants} style={{ marginLeft: '158px' }}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider></Divider>
                        <Space>
                            <Button type="primary" onClick={this.onClickOpenDrawerAddToken}>
                                Ajouter un nouveau token
                            </Button>
                        </Space>
                        <Divider></Divider>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={merchants}
                        />
                    </div>
                </Content>
                <Drawer
                    title="Ajout d'un nouveau token Urbantz"
                    placement="right"
                    closable={false}
                    onClose={this.onCloseDrawerToken}
                    visible={this.state.showDrawer}
                    width={720}
                >
                    <div>
                        <Form ref={this.formRef} onFinish={this.onFinish} {...layoutGeneral}>
                            <Form.Item
                                label="Réseau"
                                name="network_id"
                                hasFeedback
                                rules={[{ required: true, message: 'Merci de choisir un réseau.' }]}
                            >
                                <Select
                                    labelInValue={true}
                                    showSearch
                                    value={this.state.network}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchNetwork}
                                    onChange={this.handleChangeNetwork}
                                    notFoundContent={null}
                                    allowClear
                                >
                                    {optionNetwork}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Commerçant"
                                name="merchant_id"
                                hasFeedback
                                rules={[{ required: true, message: 'Merci de choisir un commerçant.' }]}
                            >
                                <Select
                                    labelInValue={true}
                                    showSearch
                                    value={this.state.merchant}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchMerchant}
                                    onChange={this.handleChangeMerchant}
                                    notFoundContent={null}
                                    allowClear
                                    disabled={disable}
                                >
                                    {optionMerchant}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Token Urbantz"
                                name="urbantz_token"
                                hasFeedback
                                rules={[{ required: true, message: 'Merci de renseigner un token.' }]}
                            >
                                <Input disabled={disable} />
                            </Form.Item>
                            <Form.Item >
                                <Space>
                                    <Button type="primary" htmlType="submit" >
                                        Enregistrer
                                    </Button>
                                    <Button onClick={this.onCloseDrawerToken} style={{ marginRight: 8 }}>
                                        Annuler
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default UrbantzToken;