import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React, { useState } from 'react';
import './App.less';
import Login from './Pages/Login/Login.js';
import Dashboard from './Pages/Dashboard/Dashboard.js';
import Users from './Pages/Users/Users.js';
import UrbantzToken from './Pages/UrbantzToken/UrbantzToken.js';
import WoopAdd from './Pages/Woop/WoopAdd.js';
import WhiteLabels from './Pages/WhiteLabel/Whitelabels.js';
import WhiteLabelAdd from './Pages/WhiteLabel/WhitelabelAdd.js';
import WhiteLabelEdit from './Pages/WhiteLabel/WhitelabelEdit.js';
import Networks from './Pages/Network/Networks.js';
import NetworkAdd from './Pages/Network/NetworkAdd.js';
import NetworkEdit from './Pages/Network/NetworkEdit.js';
import DeliveryOptions from './Pages/DeliveryOptions/DeliveryOptions.js'


import AppLayout from './AppLayout.js';
import { GlobalContext } from './GlobalContext';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, ...rest }) {
  let auth = localStorage.getItem('token');
  // Requis pour le context global
  const [global, updateGlobal] = useState({
    'profile': null,
    'config': null
  });
  return (
    <Route
      {...rest}
      render={(props) => {
        let state = {
          'global': global,
          'updateGlobal': updateGlobal
        }

        return (
          auth !== null && auth !== '' ? (

            <GlobalContext.Provider value={state}><AppLayout ><Component {...props} /></AppLayout></GlobalContext.Provider>

          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        )
      }
      }
    />
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/' component={Dashboard} />
          <PrivateRoute exact path='/users' component={Users} />
          <PrivateRoute exact path='/urbantztoken' component={UrbantzToken} />
          <PrivateRoute exact path='/deliveryoptions' component={DeliveryOptions} />
          <PrivateRoute exact path='/woop/add' component={WoopAdd} />
          <PrivateRoute exact path='/whitelabels' component={WhiteLabels} />
          <PrivateRoute exact path='/whitelabel/add' component={WhiteLabelAdd} />
          <PrivateRoute exact path='/whitelabel/:id' component={WhiteLabelEdit} />
          <PrivateRoute exact path='/networks' component={Networks} />
          <PrivateRoute exact path='/network/add' component={NetworkAdd} />
          <PrivateRoute exact path='/network/:id' component={NetworkEdit} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;